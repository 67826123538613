<template>
  <div class="miya-operation" :class="showModelBg ? 'show' : ''">
    <div class="phoneBg" @click="showModelBg = false">
      <i class="el-icon-close"></i>
    </div>
    <div class="logo-box">
      <img class="logo" src="@assets/images/home/miyaLOGO.svg" />
    </div>
    <div class="operation-panel">
      <p class="title1">{{ $t("operation.title1") }}</p>
      <div class="operationList">
        <div
          class="item"
          v-for="(item, idx) in operationList"
          :key="'ai' + idx"
          :class="{ actionOperation: item.id === actionOperation }"
          @click.stop="clickOperation(item)"
        >
          <i class="iconfont icon-chat" v-if="item.id === 'chat'"></i>
          <i class="iconfont icon-draw" v-if="item.id === 'draw'"></i>
          <p class="name">{{ item.name }}</p>
        </div>
        <p class="phoneBtn" v-if="pageWidth < 900" @click="showModelBg = true">
          {{ $t("operation.title2") }}
        </p>
      </div>
      <p class="title1">{{ $t("operation.title2") }}</p>
      <p
        class="tipText"
        :class="showModelBg ? 'phoneTipText' : ''"
        v-if="actionOperation == 'chat'"
      >
        <!-- <span style="color:green;font-weight:bold;">{{$t('ai.modelFree')}}</span> -->
        <span>{{ $t("ai.modelTip1") }}</span>
        <span>{{ $t("ai.modelTip2") }}</span>
        <span class="grey">{{ $t("ai.modelTip3") }}</span>
        <span>{{ $t("ai.modelTip4") }}</span>
        <span class="red">0.01</span>
        <span>{{ $t("ai.modelTip5") }}</span>
        <span class="grey">{{ $t("ai.modelTip6") }}</span>
        <span>{{ $t("ai.modelTip7") }}</span>
        <span class="red">0.1</span>
        <span>{{ $t("ai.modelTip8") }}</span>
        <span>{{ $t("ai.modelTip2") }}</span>
        <span class="grey">{{ $t("ai.modelTip13") }}</span>
        <span>{{ $t("ai.modelTip14") }}</span>
      </p>
      <p
        class="tipText"
        :class="showModelBg ? 'phoneTipText' : ''"
        v-if="actionOperation == 'draw'"
      >
        <!-- <span style="color:green;font-weight:bold;">{{$t('ai.modelFree')}}</span> -->
        <span>{{ $t("ai.modelTip1") }}</span>
        <span>{{ $t("ai.modelTip9") }}</span>
        <span class="grey">{{ $t("ai.modelTip10") }}</span>
        <span>{{ $t("ai.modelTip11") }}</span>
        <span class="red">0.2</span>
        <span>{{ $t("ai.modelTip12") }}</span>
      </p>
      <div class="modelList" :class="showModelBg ? 'phoneModelList' : ''">
        <div
          class="item"
          v-for="(item, idx) in modelList"
          :key="'model' + idx"
          :class="{ actionModel: item.value === actionModel }"
          @click.stop="clickModel(item)"
        >
          <p class="name">{{ item.name }}</p>
          <i class="iconfont icon-check" v-if="item.value === actionModel"></i>
        </div>
        <div style="text-align: center">
          <a href="https://www.miyaip.com/" target="_bank">
            <img
              style="width: 60%"
              :class="showModelBg ? 'adsPhoneHeight' : ''"
              src="@assets/images/ads.png"
            />
            <p>https://www.miyaip.com</p>
          </a>
        </div>
      </div>
    </div>
    <div class="contact">
      <img class="qqImg" src="@assets/images/ai/qq.png" />
      <p class="qq">{{ $t("operation.qq1") }}：822579103</p>
      <el-popover placement="top-start" title="" width="178" trigger="hover">
        <div class="contact-qqCode">
          <img class="code" src="@assets/images/ai/code.png" />
          <p class="contact-codeName">{{ $t("operation.qq2") }}</p>
        </div>
        <div class="qqBtn" slot="reference">
          <i class="iconfont icon-qq"></i><span>{{ $t("operation.qq3") }}</span>
        </div>
      </el-popover>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      operationList: [
        {
          id: "chat",
          name: "",
          actionModel: "gpt-3.5-turbo",
          list: [
            { name: "GPT 3.5 Turbo", value: "gpt-3.5-turbo" },
            { name: "GPT 4", value: "gpt-4-1106-preview" },
            { name: "GPT 4 Omni Mini", value: "gpt-4o-mini" },
            { name: "Claude 1 100k", value: "claude-1-100k" },
            // { name: "Claude 2", value: "claude-2" }, // the api is broken
            { name: "Claude 3 Haiku", value: "claude-3-haiku-20240307" },
            { name: "Claude 3 Sonnet", value: "claude-3-sonnet-20240229" },
            { name: "Claude 3 Opus", value: "claude-3-opus-20240229" },
            { name: "Claude 3.5 Sonnet", value: "claude-3-5-sonnet-20240620" },
            { name: "Gemini 1.5 Pro", value: "gemini-1.5-pro" },
          ],
        },
        {
          id: "draw",
          name: "",
          actionModel: "midjourney-imagine",
          list: [
            { name: "Midjourney (Imagine)", value: "midjourney-imagine" },
            { name: "Midjourney (Blend)", value: "midjourney-blend" },
            { name: "Insight Face (Swap)", value: "insightface-swap" },
          ],
        },
      ],
      modelList: [],
      showModelBg: false,
    };
  },
  computed: {
    ...mapGetters(["actionOperation", "actionModel"]),
  },
  created() {
    this.clickOperation(this.operationList[0]);
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.operationList[0].name = this.$t("operation.type1");
      this.operationList[1].name = this.$t("operation.type2");
    },
    clickOperation(item) {
      this.$store.commit("set_actionOperation", item.id);
      this.modelList = item.list;
      this.$store.commit("set_actionModel", item.actionModel);
      this.$router.push(`/layout`);
    },
    clickModel(item) {
      this.hidePhoneModel();
      this.$store.commit("set_actionModel", item.value);
    },
    hidePhoneModel() {
      if (this.pageWidth < 800) {
        this.showModelBg = false;
      }
    },
  },
  beforeDestroy() {},
  watch: {
    language() {
      this.getData();
    },
  },
  components: {},
};
</script>
<style scoped lang="stylus">
.adsPhoneHeight
  height:300px
.miya-operation
  width 360px
  border-right 1px solid $c4
  padding-bottom 100px
  position relative
  .phoneBg
    display none
  .logo-box
    height 71px
    border-bottom 1px solid $c4
    .logo
      width 106px
      margin 20px 0 0 24px
  .operation-panel
    padding 24px 24px 16px 24px
    .title1
      font-size $f14
      color $c2
      margin-bottom 12px
    .operationList
      display flex
      flex-wrap wrap
      margin-bottom 32px
      .item
        width calc(50% - 12px)
        margin-right 24px
        border-radius 8px
        height 81px
        display flex
        flex-direction column
        justify-content center
        background-color #F6F6F6
        text-align center
        font-size $f16
        color $c2
        cursor pointer
        &:nth-of-type(2n)
          margin-right 0
        .icon-chat, .icon-draw
          font-size 28px
          margin 0 auto 6px auto
      .actionOperation
        background: linear-gradient(132deg, #FFBF10 0%, #FF6F00 100%);
        color $c7
    .tipText
      padding 14px 16px
      line-height 18px
      font-size $f12
      color $c1
      background-color #F6F6F6
      border-radius 10px
      margin-bottom 24px
      .red
        color #FF3923
      .grey
        color $c2
  .modelList
    .item
      margin-bottom 10px
      background-color #F6F6F6
      border-radius 6px
      border 1px solid transparent
      color $c2
      font-size $f14
      padding 16px 20px
      line-height 20px
      cursor pointer
      display flex
      .name
        flex 1
      &:hover
        border 1px solid $c4
    .actionModel
      border 1px solid $c8
      color $c8
      background-color $c81
      &:hover
        border 1px solid $c8
  .contact
    position absolute
    bottom 0
    left 0
    padding 32px 24px
    width 100%
    border-top 1px solid $c4
    display flex
    line-height 20px
    .qqImg
      width 16px
      margin-right 9px
    .qq
      color $c1
      font-size $f14
      flex 1
    .qqBtn
      line-height 20px
      display flex
      font-size $f16
      color $c8
      cursor default
      .icon-qq
        margin-right 6px
        font-size $f20
.contact-codeName
  font-size $f12
  color $c2
  text-align center
@media screen and (max-width: 800px)
  .miya-operation
    position absolute
    width 70%
    top 0
    left 0
    border-right none
    padding-bottom 0
    .logo-box, .tipText, .phoneBg, .modelList, .contact
      display none
    .operation-panel
      padding 0.5rem
      .title1
        display none
      .operationList
        margin-bottom 0
        .item
          flex 1
          height auto
          line-height 1.4rem
          font-size 0.65rem
          margin-right 0.5rem
          .iconfont
            display none
        .phoneBtn
          font-size 0.65rem
          line-height 1.4rem
          margin-left 0.5rem
          color $c8
          cursor pointer
  .show
    z-index 13
    .phoneBg
      position absolute
      top 0
      left 0
      display block
      width 100vw
      height 100vh
      background-color rgba(0,0,0,0.5)
      .el-icon-close
        color $c1
        z-index 14
        position absolute
        cursor pointer
        font-size 1rem
        top 3rem
        right 1.4rem
    .operation-panel
      &:before
        content ''
        width 19rem
        height 60vh
        min-height 25rem
        position absolute
        top 2.6rem
        left 50vw
        transform translate3d(-50%,0,0)
        border-radius 0.5rem
        background-color $c7
      .tipText, .modelList
        position relative
        display block
        z-index 14
        width 18rem
      .tipText
        font-size 0.6rem
        top 2.6rem
        left 0.6rem
        line-height 1rem
      .modelList
        top 3rem
        left 0.6rem
    .contact
      display flex
      flex-wrap wrap
      position relative
      border-top none
      width 18rem
      top 2.5rem
      padding 0.6rem
      margin-left 1rem
</style>
